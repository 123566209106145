<!--
	@component:	ANZRADIO
	@desc:		Render checkbox as radio elements.
	@author: 	Yannick Herzog, info@hit-services.net
	@modified:	2018-07-19
 -->
<template>
  <li class="list-main__item">
    <button
      class="list-main__item-link flex"
      @click.prevent="onClickItem(data)"
      :class="{ 'is-disabled': data.IsLocked === 'Y' || !data.Show }"
    >
      <el-checkbox
        v-model="data.Valid"
        :disabled="data.IsLocked === 'Y' || !data.Show"
        :name="data.Xnodekey"
        class="el-radio--fake"
      ></el-checkbox>
      <span :for="data.Xnodekey" class="m-l-1">
        <span class="badge" v-if="data.MIQVzModell" :style="{ 'background-color': data.MIQVzModell }"></span>
        {{ data.Lanqkey01 }}
      </span>
    </button>

    <!-- Actions -->
    <div v-if="data.PdfOnPath || data.InfoTooltipNumber">
      <ListItemActions :data="data"></ListItemActions>
    </div>

    <!-- Debug -->
    <DebugItem v-if="debug.visible" :data="data"></DebugItem>
  </li>
</template>

<script>
import DebugItem from '@/components/DebugItem';
import ListItemActions from '@/components/List/ListItemActions';
import mixins from '@/shared/mixins/index';

export default {
  name: 'ANZRADIO',

  components: {
    DebugItem,
    ListItemActions
  },

  mixins: [mixins],

  props: {
    data: {
      type: Object,
      required: true
    },

    updateNodeKey: {
      type: String,
      required: true
    }
  },

  computed: {
    visibleControl() {
      return this.visibleControls[0].toLowerCase();
    },

    visibleControls() {
      return this.$store.state.visibleControls;
    }
  },

  data() {
    return {};
  },

  methods: {
    onClickItem(node) {
      if (node.Xreltyp === 'VAL') {
        this.$store.commit('pages/SET_PAGES_LOADING', { loading: true, nodeKey: node.Xnodekey });

        this.requestNode(node.Xnodekey).then(() => {
          this.$events.$emit(`${this.visibleControl}:refresh:child`, node);
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
